var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-col', {
    staticClass: "flex-grow-1 flex-shrink-0",
    staticStyle: {
      "max-width": "100%",
      "overflow-x": "hidden"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "block",
      "position": "relative",
      "height": "100%"
    }
  }, [[_c('v-toolbar', {
    staticStyle: {
      "height": "2.5rem"
    },
    attrs: {
      "color": "grey lighten-2",
      "light": ""
    }
  }, [_c('v-toolbar-title', {
    staticStyle: {
      "font-size": "medium",
      "height": "3rem"
    },
    domProps: {
      "textContent": _vm._s(_vm.listTitle)
    }
  })], 1), _vm.loading ? _c('v-progress-linear', {
    staticStyle: {
      "margin-top": "-0.25em"
    },
    attrs: {
      "indeterminate": "",
      "color": "blue"
    }
  }) : _vm._e(), _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "column",
      "overflow-y": "auto",
      "height": "calc(100vh - 28.5rem)"
    }
  }, [_c('v-list', {
    attrs: {
      "two-line": ""
    }
  }, [_c('v-list-item-group', {
    attrs: {
      "active-class": "blue--text"
    }
  }, [_vm._l(_vm.items, function (item, index) {
    return [_c('v-list-item', {
      key: item.index,
      on: {
        "click": function click($event) {
          _vm.showDetails(item);

          _vm.showSearchResult = false;
        }
      }
    }, [[_c('v-list-item-avatar', {
      attrs: {
        "color": "yellow accent-4"
      }
    }, [!item.avatar && item.type == 'associate' ? _c('v-icon', {
      staticStyle: {
        "color": "grey"
      }
    }, [_vm._v("mdi-account")]) : _vm._e(), !item.avatar && item.type == 'organization' ? _c('v-icon', {
      staticStyle: {
        "color": "grey"
      }
    }, [_vm._v("mdi-domain")]) : _vm._e(), item.avatar ? _c('v-img', {
      attrs: {
        "src": item.avatar
      }
    }) : _vm._e()], 1), _c('v-list-item-content', [_c('v-list-item-title', {
      staticStyle: {
        "font-weight": "bold"
      },
      attrs: {
        "color": "light-blue"
      },
      domProps: {
        "innerHTML": _vm._s(item.title)
      }
    }), item.sectors ? _c('div', {
      staticClass: "d-flex",
      staticStyle: {
        "align-items": "flex-start"
      }
    }, [_c('v-icon', {
      staticClass: "list-icon"
    }, [_vm._v("mdi-home-city-outline")]), _c('v-list-item-subtitle', {
      staticClass: "text--primary, list-subtitle, text-wrap",
      staticStyle: {
        "padding-left": "1em"
      },
      domProps: {
        "innerHTML": _vm._s(item.sectors)
      }
    })], 1) : _vm._e(), item.specialties ? _c('div', {
      staticClass: "d-flex",
      staticStyle: {
        "align-items": "flex-start"
      }
    }, [_c('v-icon', {
      staticClass: "list-icon"
    }, [_vm._v("mdi-camera-outline")]), _c('v-list-item-subtitle', {
      staticClass: "text--primary, list-subtitle, text-wrap",
      staticStyle: {
        "padding-left": "1em"
      },
      domProps: {
        "innerHTML": _vm._s(item.specialties)
      }
    })], 1) : _vm._e(), item.destinations ? _c('div', {
      staticClass: "d-flex",
      staticStyle: {
        "align-items": "flex-start"
      }
    }, [_c('v-icon', {
      staticClass: "list-icon"
    }, [_vm._v("mdi-map-marker-radius-outline")]), _c('v-list-item-subtitle', {
      staticClass: "text--primary, list-subtitle, text-wrap",
      staticStyle: {
        "padding-left": "1em"
      },
      domProps: {
        "innerHTML": _vm._s(item.destinations)
      }
    })], 1) : _vm._e()], 1)]], 2), index < _vm.entities.length - 1 ? _c('v-divider', {
      key: index
    }) : _vm._e()];
  })], 2)], 1)], 1)]], 2)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }