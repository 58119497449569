var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [_c('v-main', [_c('router-view')], 1), _c('v-footer', {
    attrs: {
      "color": "gold",
      "padless": ""
    }
  }, [_c('Footer')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }