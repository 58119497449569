import { HOST, ENDPOINT_SEARCH, ENDPOINT_TERMS } from "./config.js";
import Vue from "vue";

// import { unifySearchResult } from "../api/utils";

// axios.defaults.withCredentials = true;

export let tokens = {};

export function apiSearch(terms, pageNumber = 0) {
  return doFetch(`"${terms}"`, pageNumber);

  function doFetch(terms, pageNumber) {
    const url = pageNumber
      ? `https://${HOST}/${ENDPOINT_SEARCH}${terms}&page=${pageNumber}`
      : `https://${HOST}/${ENDPOINT_SEARCH}${terms}`;
    return fetch(
      // `https://${HOST}/${ENDPOINT_SEARCH}${terms}&items_per_page=${N_RESULT_PER_PAGE}&offset=${N_RESULT_PER_PAGE * page}`
      url
    )
      .then((response) => {
        // check for error response
        if (!response.ok) {
          // get error message from body or default to response statusText
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
        }

        let data = response.json();
        // console.log("fetching data...", data);
        return Promise.resolve(data);
      })
      .catch((error) => {
        this.errorMessage = error;
        console.error("There was an error!", error);
      });
  }
}

export function apiGetTerms() {
  // https://it-admin.peekapage.com/api-terms
  return fetch(`https://${HOST}/${ENDPOINT_TERMS}`)
    .then((response) => {
      // check for error response
      if (!response.ok) {
        // get error message from body or default to response statusText
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }

      let data = response.json();

      return Promise.resolve(data);
    })
    .catch((error) => {
      console.error("There was an error!", error);
    });
}

export function initTermMap() {
  return apiGetTerms().then((d) => {
    let m = [{}].concat(d);
    const terms = m.reduce((map, obj) => {
      map[obj.tid] = obj;
      return map;
    });
    Vue.prototype.$terms = terms;
  });
}

export function apiGetEntity(url) {
  // https://it-admin.peekapage.com/api-terms
  url = url.trim("/");
  // const options = {
  //   method: "POST",
  //   headers: {
  //     "Content-Type": "'application/json",
  //     "X-CSRF-Token": tokens.csrfToken,
  //   },
  //   credentials: "include",
  // };
  return fetch(`https://${HOST}/${url}?_format=json`)
    .then((response) => {
      // check for error response
      if (!response.ok) {
        // get error message from body or default to response statusText
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }

      let data = response.json();

      return Promise.resolve(data);
    })
    .catch((error) => {
      console.error("There was an error!", error);
    });
}

export function apiGetCatById(tid) {
  const endpoint = `https://${HOST}/api-tid?ds=${tid}&ds_p=${tid}&st=${tid}&st_p=${tid}&sp=${tid}&sp_p=${tid}`;
  return fetch(endpoint)
    .then((response) => {
      // check for error response
      if (!response.ok) {
        // get error message from body or default to response statusText
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }

      let data = response.json();

      return Promise.resolve(data);
    })
    .catch((error) => {
      console.error("There was an error!", error);
    });
}

export function apiLogin(email, password) {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    // credentials: "include",
    body: JSON.stringify({
      name: email,
      pass: password,
    }),
  };
  return fetch(`https://${HOST}/user/login?_format=json`, options)
    .then((response) => {
      // check for error response
      if (!response.ok) {
        // get error message from body or default to response statusText
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }

      let data = response.json();

      return Promise.resolve(data);
    })
    .catch((error) => {
      console.error("There was an error!", error);
    });
}

export function apiLogout(logoutToken, csrfToken) {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "'application/json",
      "X-CSRF-Token": csrfToken,
    },
    credentials: "include",
  };
  return fetch(
    `https://${HOST}/user/logout?_format=json&token=${logoutToken}`,
    options
  )
    .then((response) => {
      // check for error response
      if (!response.ok) {
        // get error message from body or default to response statusText
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }

      let data = response.json();

      return Promise.resolve(data);
    })
    .catch((error) => {
      console.error("There was an error!", error);
    });
}
