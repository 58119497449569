var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "footer text-center"
  }, [_c('div', {
    staticClass: "footer-link-section"
  }, _vm._l(_vm.links, function (s) {
    var _s$link;

    return _c('router-link', {
      key: s.label,
      staticClass: "footer-link",
      class: {
        disabled: !s.link
      },
      attrs: {
        "to": (_s$link = s.link) !== null && _s$link !== void 0 ? _s$link : ''
      }
    }, [_vm._v(_vm._s(s.label))]);
  }), 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "black--text",
    staticStyle: {
      "display": "flex",
      "align-items": "baseline"
    }
  }, [_vm._v(" Copyright © " + _vm._s(new Date().getFullYear()) + "  "), _c('a', {
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "target": "_blank",
      "href": "https://www.interpub.ca"
    }
  }, [_vm._v("International Publishing Group Inc.")]), _vm._v("  Find suppliers, destinations, and specialties. "), _c('div', {
    staticClass: "social-media"
  }, _vm._l(_vm.icons, function (icon) {
    return _c('v-btn', {
      key: icon,
      attrs: {
        "icon": ""
      }
    }, [_c('v-icon', [_vm._v(" " + _vm._s(icon) + " ")])], 1);
  }), 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }