const HOST = "it-admin.peekapage.com";

// Search
// https://it-admin.peekapage.com/api-search?text=toronto&page=7

const ENDPOINT_SEARCH = "api-search?text=";
const N_PAGE = 0; // current page number. In Drupal, we've set to get a certain number of items per page (e.g. 10)

// Terms
// https://it-admin.peekapage.com/api-terms
const ENDPOINT_TERMS = "api-terms";

export { HOST, ENDPOINT_SEARCH, N_PAGE, ENDPOINT_TERMS };
