<template>
  <v-card
    height="100%"
    style="position: relative; display: flex; flex-direction: column"
  >
    <!-- Top band -->
    <v-card color="yellow accent-4" class="home-banner">
      <!-- Header row -->
      <div
        style="
          display: flex;
          flex-direction: row;
          width: 100%;
          justify-content: space-between;
        "
      >
        <!-- <v-btn @click="mini = !mini" icon>
          <v-icon>mdi-tune</v-icon>
        </v-btn> -->
        <v-card-title style="flex-direction: column; margin-top: -0.8em">
          <div
            style="
              display: flex;
              flex-direction: row;
              align-items: center;
              cursor: pointer;
            "
            @click="
              clearSearchResult();
              $router.push({ name: 'Home' }).catch(() => {});
            "
          >
            <img
              class="yellow-outline"
              style="height: 2em; padding: 0.2em"
              src="../assets/images/LogoP.svg"
            />
            <img
              class="yellow-outline"
              style="height: 1.2rem; padding-left: 0.3em; margin-top: 0.5em"
              src="../assets/images/LogoTextBlack.svg"
            />
          </div>
          <div style="padding-left: 1em; opacity: 0.8"></div>
        </v-card-title>
        <!-- <h5>{{ cookie }}</h5> -->
        <!-- Login button -->
        <template>
          <div class="text-center" style="margin-top: 0.4em">
            <!-- <v-list-item-avatar>
              <v-icon>mdi-account</v-icon>
            </v-list-item-avatar> -->
            <v-dialog v-model="showLoginDialog" persistent width="500">
              <template v-slot:activator="{ on, attrs }" style="right: 2em">
                <v-btn v-if="isLoggedIn" text @click="logout()">Logout</v-btn>
                <v-btn v-if="!isLoggedIn" text v-bind="attrs" v-on="on">
                  Join • Login
                </v-btn>
              </template>

              <v-card>
                <div style="padding: 1em 3em">
                  <v-tabs v-model="currentTab">
                    <v-tab href="#tab-login">Login </v-tab>
                    <v-tab href="#tab-join">Join</v-tab>
                  </v-tabs>

                  <v-tabs-items v-model="currentTab">
                    <v-tab-item value="tab-login">
                      <v-card color="basil" flat>
                        <v-card-text
                          >Already a member? Login to access premium
                          features</v-card-text
                        >
                        <v-form v-model="valid">
                          <v-container>
                            <v-col>
                              <v-row cols="12" md="4">
                                <v-text-field
                                  v-model="loginEmail"
                                  :rules="emailRules"
                                  label="E-mail"
                                  required
                                ></v-text-field>
                              </v-row>

                              <v-row cols="12" md="4">
                                <v-text-field
                                  v-model="loginPassword"
                                  :append-icon="
                                    showPassword ? 'mdi-eye' : 'mdi-eye-off'
                                  "
                                  :rules="[
                                    passwordRules.required,
                                    passwordRules.min,
                                  ]"
                                  :type="showPassword ? 'text' : 'password'"
                                  name="input-10-2"
                                  label="Password"
                                  hint="At least 8 characters"
                                  value=""
                                  class="input-group--focused"
                                  @click:append="showPassword = !showPassword"
                                ></v-text-field>
                              </v-row>
                            </v-col>
                          </v-container>
                        </v-form>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item value="tab-join">
                      <v-card color="basil" flat>
                        <v-card-text
                          >Not a member yet? Sign up to access premium
                          features</v-card-text
                        >
                        <v-form v-model="valid">
                          <v-container>
                            <v-col>
                              <v-row cols="12" md="4">
                                <v-text-field
                                  v-model="loginEmail"
                                  :rules="emailRules"
                                  label="E-mail"
                                  required
                                ></v-text-field>
                              </v-row>

                              <v-row cols="12" md="4">
                                <v-text-field
                                  v-model="loginPassword"
                                  :append-icon="
                                    showPassword ? 'mdi-eye' : 'mdi-eye-off'
                                  "
                                  :rules="[
                                    passwordRules.required,
                                    passwordRules.min,
                                  ]"
                                  :type="showPassword ? 'text' : 'password'"
                                  name="input-10-2"
                                  label="Password"
                                  hint="At least 8 characters"
                                  value=""
                                  class="input-group--focused"
                                  @click:append="showPassword = !showPassword"
                                ></v-text-field>
                              </v-row>
                            </v-col>
                          </v-container>
                        </v-form>
                      </v-card>
                    </v-tab-item>
                  </v-tabs-items>
                </div>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="showLoginDialog = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    color="primary"
                    text
                    @click="login(loginEmail, loginPassword, currentTab)"
                    v-text="currentTab == 'tab-login' ? 'Login' : 'Join'"
                  >
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </template>
      </div>

      <!-- Big Arrow -->
      <div style="display: flex; flex-direction: row">
        <h3 style="align-self: end; margin-bottom: -10px">
          Canada’s Travel Industry Directory
        </h3>
        <div class="bg-arrow"></div>
      </div>

      <!-- Auto complete -->
      <v-card-text class="searchbox">
        <v-autocomplete
          ref="autocomplete"
          v-model="select"
          :items="mini ? items : []"
          :loading="isLoading"
          :search-input.sync="search"
          @keydown.enter="onEnterKey"
          no-filter
          clearable
          background-color="white"
          outlined
          hide-no-data
          hide-details
          item-text="title"
          item-value="search_api_excerpt"
          label=""
          :placeholder="searchPlaceHolder"
          return-object
          append-icon=""
        >
          <template v-slot:item="{ item }">
            <!-- <v-list-item-avatar
              color="indigo"
              class="text-body-2 font-weight-light white--text"
            >
              {{ item.title.charAt(0) }}
            </v-list-item-avatar> -->
            <v-list-item-content>
              <v-list-item-title v-html="item.title"></v-list-item-title>
              <v-list-item-subtitle
                v-html="item.excerpt"
              ></v-list-item-subtitle>
            </v-list-item-content>
            <!-- <v-list-item-avatar
              color="indigo"
              class="font-weight-light white--text"
            >
              {{ item.score }}
            </v-list-item-avatar> -->
          </template>
        </v-autocomplete>
      </v-card-text>
      <!-- <v-divider></v-divider> -->
      <!-- <v-expand-transition>
        <v-list v-if="model">
          <v-list-item v-for="(field, i) in fields" :key="i">
            <v-list-item-content>
              <v-list-item-title v-text="field.value"></v-list-item-title>
              <v-list-item-subtitle v-text="field.key"></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-expand-transition> -->
      <!-- <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="!select"
          color="white darken-3"
          @click="select = null"
        >
          Clear
          <v-icon right> mdi-close-circle </v-icon>
        </v-btn>
      </v-card-actions> -->
      <div
        style="
          opacity: 0.5;
          margin-top: -3em;
          padding-bottom: 1.5em;
          font-size: small;
        "
        v-if="search && this.searchPhraseShortest < this.MIN_SEARCH_STR_LENGTH"
      >
        {{
          `Words shorter than ${MIN_SEARCH_STR_LENGTH} characters will be ignored`
        }}
      </div>
    </v-card>

    <!-- Main content -->
    <div
      style="display: block; height: 100%; overflow-y: auto; position: relative"
    >
      <v-card
        style="display: flex; flex-grow: 1; flex-direction: row; height: 100%"
      >
        <v-navigation-drawer
          class="flex-shrink-0"
          v-model="drawer"
          :mini-variant.sync="mini"
          permanent
        >
          <div color="gray accent-4" class="filter-header" light>
            <v-btn @click="mini = !mini" icon>
              <v-icon>mdi-tune</v-icon>
            </v-btn>
            <h5 style="align-self: center" v-if="!mini && showSearchResult">
              Filters
            </h5>
            <h5 style="align-self: center" v-if="!mini && !showSearchResult">
              Browse
            </h5>
            <v-btn v-if="!mini" icon @click.stop="mini = !mini">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </div>

          <v-divider></v-divider>
          <v-list dense v-if="mini">
            <v-list-item v-for="item in menuItems" :key="item.title" link>
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <div
            style="
              font-size: small;
              height: calc(100vh - 25.5rem);
              width: 100%;
              display: block;
              position: absolute;
              overflow-y: auto;
              overflow-x: auto;
            "
          >
            <v-treeview
              ref="tree"
              v-if="!mini"
              dense
              :selectable="showSearchResult"
              :value.sync="selectedNodes"
              selected-color="primary"
              :items="filteredCategories"
              @input="searchTerm && onFilter($event)"
            >
              <template v-if="!showSearchResult" v-slot:label="{ item }">
                <v-tooltip
                  top
                  color="primary"
                  dark
                  open-delay="500"
                  hidden="true"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <router-link
                      v-if="!showSearchResult"
                      :to="makeRouterLink(item)"
                      :style="routerLinkStyle(item)"
                      :is="makeRouterLink(item) ? 'router-link' : 'span'"
                    >
                      <v-icon v-if="item.icon">
                        {{ item.icon }}
                      </v-icon>
                      <v-chip
                        @click="clearSearchResult()"
                        :style="makeRouterLink(item) ? 'cursor: pointer' : ''"
                        small
                        label
                        v-bind="attrs"
                        v-on="on"
                        color="white"
                      >
                        {{ item.name }}
                      </v-chip>
                    </router-link>
                  </template>
                  <span>{{ item.name }}</span>
                </v-tooltip>
              </template>
              <template v-if="showSearchResult" v-slot:prepend="{ item }">
                <v-icon v-if="item.icon">
                  {{ item.icon }}
                </v-icon>
              </template>
            </v-treeview>
          </div>
        </v-navigation-drawer>

        <v-col
          class="flex-grow-1 flex-shrink-0"
          style="max-width: 100%; overflow-x: hidden"
        >
          <router-view v-show="!showSearchResult"></router-view>
          <!-- result list -->
          <!-- <pre>{{ filteredCategories }}</pre> -->
          <div
            v-show="showSearchResult"
            style="
              display: block;
              position: relative;
              height: 100%;
              margin-top: -0.5rem;
            "
          >
            <template>
              <v-toolbar color="grey lighten-2" light style="height: 2.5rem">
                <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
                <v-toolbar-title
                  v-text="searchTitle"
                  style="font-size: medium; height: 3rem"
                >
                </v-toolbar-title>
                <!-- <v-spacer></v-spacer>
                <v-btn icon>
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
                <v-btn icon>
                  <v-icon>mdi-checkbox-marked-circle</v-icon>
                </v-btn> -->
              </v-toolbar>
              <div
                style="
                  height: calc(100vh - 27rem);
                  display: flex;
                  flex-direction: column;
                  overflow-y: auto;
                "
              >
                <v-list two-line>
                  <v-list-item-group
                    v-model="selected"
                    active-class="blue--text"
                  >
                    <template>
                      <div :key="item.index" v-for="(item, index) in items">
                        <v-list-item
                          @click="
                            showDetails(item);
                            showSearchResult = false;
                          "
                        >
                          <template v-slot:default="{ active }">
                            <v-list-item-avatar color="yellow accent-4">
                              <v-icon
                                style="color: grey"
                                v-if="!item.avatar && item.type == 'associate'"
                                >mdi-account</v-icon
                              >
                              <v-icon
                                style="color: grey"
                                v-if="
                                  !item.avatar && item.type == 'organization'
                                "
                                >mdi-domain</v-icon
                              >
                              <v-img
                                v-if="item.avatar"
                                :src="item.avatar"
                              ></v-img>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title
                                color="light-blue"
                                style="font-weight: bold"
                              >
                                <text-highlight
                                  :queries="searchTerm.split(' ')"
                                >
                                  {{ item.title }}
                                </text-highlight>
                              </v-list-item-title>
                              <!-- <span>{{ item.tids }}</span> -->
                              <div
                                class="d-flex"
                                style="align-items: flex-start"
                              >
                                <v-list-item-subtitle
                                  class="text--primary, list-subtitle, text-wrap"
                                  v-html="item.organization"
                                  v-on:click.stop
                                >
                                </v-list-item-subtitle>
                              </div>
                              <div
                                class="d-flex"
                                style="align-items: flex-start"
                                v-if="item.sectors"
                              >
                                <v-icon class="list-icon"
                                  >mdi-home-city-outline</v-icon
                                >
                                <v-list-item-subtitle
                                  style="padding-left: 1em"
                                  class="text--primary, list-subtitle, text-wrap"
                                >
                                  <text-highlight
                                    :queries="searchTerm.split(' ')"
                                  >
                                    {{ item.sectors }}
                                  </text-highlight>
                                </v-list-item-subtitle>
                              </div>
                              <div
                                class="d-flex"
                                style="align-items: flex-start"
                                v-if="item.specialties"
                              >
                                <v-icon class="list-icon"
                                  >mdi-camera-outline</v-icon
                                >
                                <v-list-item-subtitle
                                  style="padding-left: 1em"
                                  class="text--primary, list-subtitle, text-wrap"
                                >
                                  <text-highlight
                                    :queries="searchTerm.split(' ')"
                                  >
                                    {{ item.specialties }}
                                  </text-highlight>
                                </v-list-item-subtitle>
                              </div>
                              <div
                                class="d-flex"
                                style="align-items: flex-start"
                                v-if="item.destinations"
                              >
                                <v-icon class="list-icon"
                                  >mdi-map-marker-radius-outline</v-icon
                                >
                                <v-list-item-subtitle
                                  style="padding-left: 1em"
                                  class="text--primary, list-subtitle, text-wrap"
                                >
                                  <text-highlight
                                    :queries="searchTerm.split(' ')"
                                  >
                                    {{ item.destinations }}
                                  </text-highlight>
                                </v-list-item-subtitle>
                              </div>

                              <!-- <div
                              class="d-flex"
                              style="align-items: flex-start"
                              v-if="item.hints"
                            >
                              <v-icon class="list-icon">mdi-magnify</v-icon>
                              <v-list-item-subtitle
                                class="text-wrap"
                                style="
                                  font-size: 0.7em;
                                  margin-top: 0.2em;
                                  padding-left: 1em;
                                "
                                v-html="item.hints"
                              >
                              </v-list-item-subtitle>
                            </div> -->

                              <!-- <div
                              class="d-flex"
                              style="align-items: flex-start"
                              v-if="active"
                            >
                              <v-list-item-subtitle
                                class="text-wrap"
                                style="
                                  font-size: 0.7em;
                                  margin-top: 0.2em;
                                  padding-left: 1em;
                                "
                                v-html="item.link"
                              >
                              </v-list-item-subtitle>
                            </div> -->
                            </v-list-item-content>

                            <v-list-item-action>
                              <v-list-item-action-text
                                color="grey lighten-3"
                                v-text="item.score"
                              ></v-list-item-action-text>

                              <v-icon v-if="!active" color="grey lighten-1">
                                <!-- mdi-star-outline -->
                              </v-icon>

                              <v-icon v-else color="yellow darken-3">
                                <!-- mdi-star -->
                              </v-icon>
                            </v-list-item-action>
                          </template>
                        </v-list-item>

                        <v-divider
                          v-if="index < items.length - 1"
                          :key="index"
                        ></v-divider>
                      </div>
                    </template>
                  </v-list-item-group>
                </v-list>
              </div>
            </template>
          </div>
        </v-col>
        <!-- <v-col
          cols="4"
          style="background: lightblue"
          class="flex-grow-0 flex-shrink-1 d-md-none d-lg-flex"
          >hey adf aafadfdasf d fadfas
        </v-col> -->
      </v-card>
    </div>
  </v-card>
</template>

<script>
import {
  apiSearch,
  apiGetTerms,
  apiLogin,
  apiLogout,
  initTermMap,
  tokens,
} from "../api/fetch";
import { HOST } from "../api/config";
import Vue from "vue";
import TextHighlight from "vue-text-highlight";
// import debounce from "debounce";

export default {
  name: "HomeBanner",
  components: {
    TextHighlight,
  },

  data: () => ({
    MIN_SEARCH_STR_LENGTH: 3,
    searchPhraseShortest: 0,
    searchPhraseLongest: 0,
    searchPlaceHolder: "Search all suppliers, destinations, and specialties",
    cookie: document.cookie,
    TITLE_LEN_LIMIT: 60,
    showSearchResult: false,
    searchTerm: "",
    entries: [],
    isLoading: false,
    toDiscardSearchResult: false,
    select: null,
    search: null,

    // Used when converting escaped HTML to unescaped
    dummyHtmlElement: document.createElement("textarea"),

    // Login dialog
    showLoginDialog: false,
    currentTab: null,
    valid: false,

    isLoggedIn: false,
    logoutToken: null,
    csrfToken: null,
    showPassword: false,
    loginEmail: "",
    loginPassword: "",
    emailRules: [
      (v) =>
        !v ||
        true ||
        // /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "E-mail must be valid",
    ],
    passwordRules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 8 || "Min 8 characters",
      emailMatch: () => `The email and password you entered don't match`,
    },

    // Drawer
    drawer: true,
    menuItems: [
      { title: "Suppliers", icon: "mdi-home-city-outline" },
      { title: "Destinations", icon: "mdi-map-marker-radius-outline" },
      { title: "Specialties", icon: "mdi-camera-outline" },
    ],
    mini: true,

    // Table
    headers: [
      {
        text: "Name",
        align: "start",
        // filterable: false,
        value: "title",
      },
      { text: "Specialties", value: "specialties" },
      { text: "Sectors", value: "sectors" },
      { text: "Destinations", value: "destinations" },
      { text: "Hints", value: "hints" },
    ],

    // List
    selected: [],

    // Filters
    orgTerms: [],
    categories: [],
    filteredCategories: [],
    idsInSearchResult: new Set(),
    selectedNodes: [],
  }),

  computed: {
    searchTitle() {
      const s = this.items.length > 1 ? "s" : "";
      const isFiltered = this.selectedNodes.length > 0 ? "(filtered)" : "";

      if (this.isLoading) {
        return "Searching ...";
      } else if (!this.isLoading && !this.searchTerm) {
        return "Let's start searching";
      } else if (!this.isLoading && this.searchTerm) {
        const splitTerms = this.searchTerm.split(" ").join('", "');
        return `${this.items.length} ${isFiltered} result${s} for "${splitTerms}"`;
      } else {
        return "";
      }
    },

    fields() {
      if (!this.select) return [];

      return Object.keys(this.select).map((key) => {
        return {
          key,
          value: this.select[key] || "n/a",
        };
      });
    },

    items() {
      return this.entries
        ?.map((i) => ({
          index: i.uuid,
          tids: Array.from(
            new Set([
              ...(i.sector_ids || []),
              ...(i.specialty_ids || []),
              ...(i.destination_ids || []),
            ])
          ),
          type: i.type.toLowerCase().split(" - ")[1],
          title: this.decodeHtml(unescape(i.title)),
          organization: i.field_organization
            ? this.decodeHtml(
                i.field_organization.replace(
                  "/profile-organization",
                  "/details/profile-organization"
                )
              )
            : null,
          hints: unescape(i.search_api_excerpt),
          score: i.search_api_relevance,
          specialties: this.decodeHtml(i.specialties)
            .split("|")
            .sort()
            .join(", "),
          sectors: this.decodeHtml(i.sectors).split("|").sort().join(", "),
          destinations: this.decodeHtml(i.destinations)
            .split("|")
            .sort()
            .join(", "),
          link: i.search_api_rendered_item?.match(
            /<a href="\/profile-.+" rel="bookmark">/
          )
            ? i.search_api_rendered_item
                ?.match(/<a href="\/profile-.+" rel="bookmark">/)[0]
                ?.match(/\/profile[^"]+/)[0]
            : "",
          avatar: this.getAvatar(i),
          action: true,
          details: i.search_api_rendered_item,
        }))
        .filter(
          (i) =>
            i.tids.length === 0 ||
            i.tids.filter((id) =>
              this.selectedNodes.length > 0
                ? this.selectedNodes.includes(id)
                : true
            ).length > 0
        );
    },
  },

  created() {
    initTermMap().then((this.terms = Vue.prototype.$terms));
  },

  watch: {
    search(val) {
      if (!val) {
        val = "";
      }
      val = val.toLocaleLowerCase();

      /* If there is a search in progress and the input is new:
        clear scheduled/delayed search request and mark to discard incoming
        search result incase already sent the request
      */
      if (this._timerId && val?.trim() !== this.searchTerm) {
        this.discardIncomingSearchResult();
      }

      /* Ignore spaces*/
      if (this.isLoading || val?.trim() === this.searchTerm) {
        return;
      }

      /* If the input is too short, clear current search result
         and discard incoming result
      */
      val = val
        .replace(/[^a-zA-Z0-9.]/g, " ")
        .replace(/\s+/g, " ")
        .trim();
      this.searchPhraseShortest = Math.min(
        ...val.split(" ").map((s) => s.length)
      );
      this.searchPhraseLongest = Math.max(
        ...val.split(" ").map((s) => s.length)
      );
      if (!val || this.searchPhraseLongest < this.MIN_SEARCH_STR_LENGTH) {
        // this.clearSearchResult(); // ! This has to be removed, or the filter will not work
        this.discardIncomingSearchResult();
        return;
      }

      /* 
        If we get so far, the input should be long enough and new
      */
      val = val
        .split(" ")
        .filter((s) => s.length >= this.MIN_SEARCH_STR_LENGTH)
        .join(" ");

      this.toDiscardSearchResult = false;
      this.fetchEntriesDebounced(val);
    },

    $route(to) {
      if (to.name === "Home") {
        this.clearSearchResult();
      }
    },
  },

  mounted() {
    this.$nextTick(function () {
      // Code that will run only after the
      // entire view has been re-rendered
      this.mini = this.$vuetify.breakpoint.xs;
      this.autocomplete = this.$refs.autocomplete;
    });
    this.$root.$on("showSignupDialog", () => {
      this.currentTab = "tab-join";
      this.showLoginDialog = true;
    });
  },

  beforeMount() {
    apiGetTerms().then((d) => {
      this.orgTerms = [...d];
      this.categories = this.createTree(this.orgTerms, null);
      // console.log("this.categories", this.categories);
      this.filteredCategories = [...this.categories];
    });
    initTermMap().then((this.terms = Vue.prototype.$terms));
  },

  methods: {
    discardIncomingSearchResult() {
      clearTimeout(this._timerId);
      this.isLoading = false;
      this.toDiscardSearchResult = true;
    },

    entityHasNoTid(entity) {
      const hasNoTid =
        entity.sector_ids?.length === 0 &&
        entity.specialty_ids?.length === 0 &&
        entity.destination_ids?.length === 0;

      return hasNoTid;
    },

    // type: 'tab-login' or 'tab-join'
    login(email, password, type) {
      if (type == "tab-login") {
        apiLogin(email, password)
          .then((res) => {
            console.log("login", res);
            if (res.csrf_token) {
              this.isLoggedIn = true;
              tokens.logoutToken = res.logout_token;
              tokens.csrfToken = res.csrf_token;
              this.showLoginDialog = false;
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },

    logout() {
      apiLogout(tokens.logoutToken, tokens.csrfToken).then(() => {
        this.isLoggedIn = false;
      });
    },

    clearSearchResult() {
      this.showSearchResult = false;
      this.searchTerm = "";
      this.entries = [];
      this.filteredCategories = this.createTree(this.orgTerms, null);
    },

    showDetails(item) {
      // console.log("showDetails", item);
      this.clearSearchResult();
      item.details += "";
      let type, title;
      [type, title] = [...item.link.split("/").filter((s) => s !== "")];
      this.$router
        .push({
          // path: `/details${item.link}`,
          // path: `/details/${item.link}`,
          name: "Details",
          params: {
            type: type,
            name: title,
            details: item.details,
          },
        })
        .catch(() => {});
    },

    clearSearch() {
      this.searchTerm = "";
      this.entries = [];
      this.updateResultTerms(this.entries);
      this.filteredCategories = this.createTree(this.orgTerms, null);
      // this.selectedNodes = [];
      // this.onFilter([]);
    },

    createTree(orgTerms, termsToKeep) {
      // console.log("termsToKeep", termsToKeep);
      // console.log("this.idsInSearchResult", this.idsInSearchResult);
      let terms = [...orgTerms];

      const root = [
        {
          id: "1",
          icon: "mdi-home-city-outline",
          vid: "",
          tid: "1",
          name: "Suppliers",
          nameValue: "sectors",
          parent_id: "",
        },
        {
          id: "2",
          icon: "mdi-camera-outline",
          vid: "",
          tid: "2",
          name: "Specialties",
          nameValue: "specialties",
          parent_id: "",
        },
        {
          id: "3",
          icon: "mdi-map-marker-radius-outline",
          vid: "",
          tid: "3",
          name: "Destinations",
          nameValue: "countries",
          parent_id: "",
        },
      ];

      // convert terms to view model shape
      for (let c of root) {
        terms.push(c);
      }

      terms.sort((a, b) => {
        if (a.name > b.name && a.tid > 3 && b.tid > 3) {
          return 1;
        } else if (a.name < b.name && a.tid > 3 && b.tid > 3) {
          return -1;
        } else {
          return 0;
        }
      });

      // mark leaf nodes that need to be kept, build view model
      for (let t of terms) {
        t.children = [];
        t.keep = termsToKeep?.size > 0 ? termsToKeep.has(t.tid) : true;
        t.id = t.tid;
        t.selected = true;
        if (t.vid && !t.parent_id) {
          t.parent_id = root.find(
            (r) => r.nameValue.toLowerCase() == t.vid.toLowerCase()
          ).tid;
        }
      }

      // console.log(
      //   "termsToKeep",
      //   termsToKeep,
      //   terms.filter((t) => t.keep)
      // );

      // build tree
      for (let l of terms.filter((j) => !!j.parent_id)) {
        let parent = terms.find((i) => i.tid == l.parent_id);
        if (parent) {
          parent.keep = l.keep ? true : parent.keep;
        }
      }

      const keepers = terms.filter((j) => j.keep);
      // console.log("createTree:termsToKeep", keepers);
      for (const k of keepers) {
        markParent(terms, k);
      }

      for (const k of terms.filter((j) => j.keep)) {
        let parent = terms.find((i) => i.tid === k.parent_id);
        if (parent && parent.keep) {
          parent.children.push(k);
        }
      }

      function markParent(terms, node) {
        const parent = terms.find((i) => i.tid == node.parent_id);
        if (parent) {
          // parent.children.push(node);
          parent.keep = true;
        }

        if (parent?.parent_id) {
          markParent(terms, parent);
        }
      }

      let organized = terms.filter((i) => i.vid == "" && i.children.length > 0);
      // console.log("organized", organized);
      // console.log("terms", terms);

      // this.selectedNodes = Array.from(this.idsInSearchResult);
      // this.selectedNodes = Array.from(termsToKeep || new Set());
      this.selectedNodes = [];
      // console.log("createTree", this.selectedNodes, termsToKeep);
      return organized;
    },

    makeRouterLink(item) {
      if (!item.view_taxonomy_term || !item.tid) {
        return "";
      }

      const path = item.view_taxonomy_term?.replace("category/", "cat/");
      return `${path}?tid=${item.tid}`;
    },

    routerLinkStyle(item) {
      return this.makeRouterLink(item)
        ? "text-decoration: underline; text-decoration-color: black; cursor: pointer"
        : "text-decoration: none; ";
    },

    goTermLink(item) {
      console.log("goTermLink", item);
      if (!item.view_taxonomy_term) {
        return;
      }
      const params = item.view_taxonomy_term?.split("/").slice(-2);
      const cat = params[0];
      const name = params[1];
      console.log("goTermLink", params);
      this.$router
        .push({
          name: `Category`,
          params: { cat: cat, name: name },
          query: { tid: item.tid },
        })
        .catch(() => {});
    },

    updateResultTerms(entries) {
      this.idsInSearchResult = resultIds(entries);
      // console.log("updateResultTerms", this.idsInSearchResult);

      function resultIds(entries) {
        // console.log("updateResultTerms: entries", entries);
        if (entries.length < 1) {
          return new Set();
        }
        let idSet = new Set();
        for (const e of entries) {
          for (const id of e.tids || []) {
            idSet.add(id);
          }
        }
        return idSet;
      }
    },

    getAvatar(i) {
      if (i.type?.toLowerCase().includes("organization")) {
        return i.field_logo ? `https://${HOST}${unescape(i.field_logo)}` : "";
      } else if (i.type?.toLowerCase().includes("associate")) {
        return i.field_image ? `https://${HOST}${unescape(i.field_image)}` : "";
      }
    },

    onEnterKey() {
      // console.log(this.autocomplete.value);
      this.autocomplete.blur();
    },

    onFilter(selected) {
      // console.log("tree select event", selected);
      // console.log(this.$refs.tree.value);
      this.selectedNodes = selected;
      this.showSearchResult = true;
    },

    fetchEntriesDebounced(v) {
      this.isLoading = true;
      this.searchTerm = v;
      // cancel pending call
      if (this._timerId) {
        clearTimeout(this._timerId);
      }

      // delay new call
      this._timerId = setTimeout(() => {
        this.querySelections(v);
      }, 500);
    },

    querySelections(v) {
      this.entries = [];
      apiSearch(v, 0).then((data) => {
        if (this.toDiscardSearchResult) {
          return [];
        }
        // console.log("search result:", data);
        this.entries = data.rows;
        this.isLoading = false;
        this.showSearchResult = true;

        // items is view model
        this.selectedNodes = [];
        this.updateResultTerms(this.items);

        this.filteredCategories = this.createTree(
          this.orgTerms,
          this.idsInSearchResult.size > 0 ? this.idsInSearchResult : null
        );
        // console.log("this.filteredCategories", this.filteredCategories);
        // console.log("his.orgTerms", this.orgTerms);
        // console.log("this.idsInSearchResult", this.idsInSearchResult);

        return data;
      });
    },

    decodeHtml: function (html) {
      this.dummyHtmlElement.innerHTML = html;
      return this.dummyHtmlElement.value;
    },
  },
};
</script>

<style scoped>
.home-banner {
  padding: 1em;
  max-height: 18rem;
  align-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 601px) {
  .bg-arrow {
    background-image: url(../assets/images/inside_plane_black.svg);
    background-position: right bottom;
    background-origin: content-box;
    width: 12em;
    height: 6em;
    /* padding: 1em 10em; */
  }
}

.yellow-outline {
  text-shadow: -1px -1px 0 #fffd60, 0 -1px 0 #fffd60, 1px -1px 0 #fffd60,
    1px 0 0 #fffd60, 1px 1px 0 #fffd60, 0 1px 0 #fffd60, -1px 1px 0 #fffd60,
    -1px 0 0 #fffd60;
}
.searchbox {
  max-width: 50em;
}

.list-subtitle {
  padding-left: 1em;
}
.list-icon {
  font-size: 1.2em !important;
}
.filter-header {
  padding: 0 0.5em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
/* Custom text highlight color */
span >>> .text__highlight {
  /* font-weight: bold; */
  /* font-style: oblique; */
  background-color: #eee;
}
</style>
