import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";

import "./assets/css/style.css";

Vue.config.productionTip = true;
Vue.prototype.$terms = null;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),

  el: "#app",
  data: () => ({
    drawer: false,
    group: null,
  }),

  watch: {
    watch: {
      $route(to) {
        document.title = to.meta.title || "Inside Travel";
      },
    },
  },
}).$mount("#app");
