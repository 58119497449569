<template>
  <div style="overflow-y: auto; height: calc(100vh - 24.5rem)">
    <div v-if="!isNotFound">
      <div v-if="!entity" style="text-align: center; margin-top: 20%">
        Nothing to show from this URL
      </div>
      <div v-if="entity">
        <!-- <pre>{{entity}}</pre> -->
        <div class="mx-auto" max-width="800" style="padding: 3em; margin: 2em">
          <div style="display: flex; flex-direction: row">
            <v-avatar size="56" style="margin-right: 0.5em">
              <v-icon
                style="color: grey; background: #ffd600"
                v-if="!getAvatarUrl(entity)"
                >{{
                  g(entity.type)?.target_id?.includes("organization")
                    ? "mdi-domain"
                    : "mdi-account"
                }}</v-icon
              >
              <img
                v-if="getAvatarUrl(entity)"
                alt="avatar"
                :src="getAvatarUrl(entity)"
                aspect-ratio="1"
              />
            </v-avatar>
            <div>
              <h1>
                {{ g(entity.title) }}
              </h1>
              <div
                v-if="g(entity.field_job_title_territory)"
                v-html="g(entity.field_job_title_territory)"
              ></div>
              <v-card-subtitle
                v-if="g(entity.field_addresses_html)"
                v-html="g(entity.field_addresses_html)"
                class="address"
              ></v-card-subtitle>
            </div>
          </div>

          <v-divider style="margin-top: 10px" v-if="g(entity.body)"></v-divider>

          <v-card-text
            v-if="g(entity.body)"
            class="text--primary"
            v-html="g(entity.body, processBody)"
          ></v-card-text>

          <v-divider style="margin-bottom: 10px"></v-divider>

          <div class="contact-section">
            <!-- Skype -->
            <div
              v-if="gs(entity.field_skype).length > 0"
              style="display: flex; flex-direction: row"
            >
              <v-chip-group
                class="tight"
                style="display: inline-block; margin: 0 0.1em 0 -1em"
              >
                <v-icon left small> mdi-tablet-cellphone </v-icon>
                Skype:
              </v-chip-group>
              <v-chip-group column>
                <v-chip
                  v-for="s of gs(entity.field_skype)"
                  :key="s.first + s.second"
                  link
                  small
                  :href="`skype:${s.second}?call`"
                >
                  {{ s.first }}
                </v-chip>
              </v-chip-group>
            </div>

            <!-- Tel -->
            <div
              v-if="gs(entity.field_tels).length > 0"
              style="display: flex; flex-direction: row"
            >
              <v-chip-group
                class="tight"
                style="display: inline-block; margin: 0 0.1em 0 -1em"
              >
                <v-icon left small> mdi-phone-outline </v-icon>
                Tel:
              </v-chip-group>
              <v-chip-group column>
                <v-chip
                  v-for="e of gs(entity.field_tels)"
                  :key="e.first + e.second"
                  link
                  small
                  :href="`tel:${e.second}`"
                >
                  {{ e.first ? e.first?.replace(/:$/, "") : e.second }}
                </v-chip>
              </v-chip-group>
            </div>

            <!-- Emails -->
            <div
              v-if="gs(entity.field_emails).length > 0"
              style="display: flex; flex-direction: row"
            >
              <v-chip-group
                class="tight"
                style="display: inline-block; margin: 0 0.1em 0 -1em"
              >
                <v-icon left small> mdi-email-outline </v-icon>
                Email:
              </v-chip-group>
              <v-chip-group column>
                <v-chip
                  v-for="e of gs(entity.field_emails)"
                  :key="e.first + e.second"
                  link
                  small
                  :href="`mailto:${e.second}`"
                >
                  {{ e.first ? e.first?.replace(/:$/, "") : e.second }}
                </v-chip>
              </v-chip-group>
            </div>

            <!-- Websites -->
            <div
              v-if="gs(entity.field_websites).length > 0"
              style="display: flex; flex-direction: row"
            >
              <v-chip-group
                class="tight"
                style="display: inline-block; margin: 0 0.1em 0 -1em"
              >
                <v-icon left small> mdi-web </v-icon>
                Website:
              </v-chip-group>
              <v-chip-group column>
                <v-chip
                  v-for="e of gs(entity.field_websites)"
                  :key="e.title + e.uri"
                  link
                  small
                  :href="`${e.uri}`"
                  target="_blank"
                >
                  {{ e.title || e.uri }}
                </v-chip>
              </v-chip-group>
            </div>

            <!-- Headquarters -->
            <div
              v-if="g(entity.field_headquarter)"
              style="display: flex; flex-direction: row"
            >
              <v-chip-group
                class="tight"
                style="display: inline-block; margin: 0 0.1em 0 -1em"
              >
                <v-icon left small> mdi-domain </v-icon>
                Headquarter:
              </v-chip-group>
              <v-chip-group column>
                <v-chip small>
                  <router-link
                    tag="span"
                    :to="`/details${g(entity.field_headquarter).url}`"
                  >
                    Headquarter
                  </router-link>
                </v-chip>
              </v-chip-group>
            </div>

            <!-- <div>
          {{ gs(entity.field_branches) }}
        </div>
        -->

            <!-- Organizations -->
            <div
              v-if="gs(entity.field_organization).length > 0"
              style="display: flex; flex-direction: row"
            >
              <v-chip-group
                class="tight"
                style="display: inline-block; margin: 0 0.1em 0 -1em"
              >
                <v-icon left small> mdi-domain </v-icon>
                Organization:
              </v-chip-group>
              <v-chip-group column>
                <v-chip
                  v-for="e of gs(entity.field_organization)"
                  :key="e.url"
                  small
                >
                  <router-link
                    tag="span"
                    v-if="e?.type"
                    :to="{
                      name: 'Details',
                      params: { type: e.type, name: e.name, details: '' },
                    }"
                  >
                    {{ e.title }}
                  </router-link>
                </v-chip>
              </v-chip-group>
            </div>

            <!-- Associates -->
            <div
              v-if="gs(entity.field_associate).length > 0"
              style="display: flex; flex-direction: row"
            >
              <v-chip-group
                class="tight"
                style="display: inline-block; margin: 0 0.1em 0 -1em"
              >
                <v-icon left small> mdi-account-multiple-outline </v-icon>
                Team:
              </v-chip-group>
              <v-chip-group column>
                <v-chip
                  v-for="e of gs(entity.field_associate)"
                  :key="e.url"
                  small
                >
                  <router-link
                    tag="span"
                    v-if="e?.type"
                    :to="{
                      name: 'Details',
                      params: { type: e.type, name: e.name, details: '' },
                    }"
                  >
                    {{ e.title }}
                  </router-link>
                </v-chip>
              </v-chip-group>
            </div>

            <!-- Social Media -->
            <div v-if="gs(entity.field_social_media_links).length > 0">
              <div>
                <v-btn
                  v-for="l of genSocialLink(entity)"
                  :key="l.platform + l.link"
                  dark
                  fab
                  bottom
                  color="blue"
                  small
                  :href="l.link"
                  target="_blank"
                  style="margin: 1em"
                >
                  <v-icon>mdi-{{ l.platform }}</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
          <!-- <v-divider style="margin-top: 10px"></v-divider> -->

          <div class="tags">
            <!-- Sector -->
            <div
              v-if="
                gs(entity.field_sector)
                  ?.map((o) => terms[o.target_id]?.name)
                  ?.filter((i) => !!i)?.length > 0
              "
              style="display: flex; flex-direction: row"
            >
              <v-chip-group
                class="tight"
                style="display: inline-block; margin: 0 0.1em 0 -1em"
              >
                Sector:
              </v-chip-group>
              <v-chip-group column>
                <v-chip
                  small
                  @click="goTermLink(o)"
                  v-for="o in gs(entity.field_sector).sort(compareTerms)"
                  :key="o.tid"
                >
                  {{ (terms && terms[o.target_id]?.name) || "" }}
                </v-chip>
              </v-chip-group>
            </div>

            <!-- Destinations -->
            <div
              v-if="gs(entity.field_destinations).length > 0"
              style="display: flex; flex-direction: row"
            >
              <v-chip-group
                class="tight"
                style="display: inline-block; margin: 0 0.1em 0 -1em"
              >
                Destinations:
              </v-chip-group>
              <v-chip-group column>
                <v-chip
                  small
                  @click="goTermLink(o)"
                  v-for="o in gs(entity.field_destinations).sort(compareTerms)"
                  :key="o.tid"
                >
                  {{ (terms && terms[o.target_id]?.name) || "" }}
                </v-chip>
              </v-chip-group>
            </div>

            <!-- Specialties -->
            <div
              v-if="gs(entity.field_specialties).length > 0"
              style="display: flex; flex-direction: row"
            >
              <v-chip-group
                class="tight"
                style="display: inline-block; margin: 0 0.1em 0 -1em"
              >
                Specialties:
              </v-chip-group>
              <v-chip-group column>
                <v-chip
                  small
                  @click="goTermLink(o)"
                  v-for="o in gs(entity.field_specialties).sort(compareTerms)"
                  :key="o.url"
                >
                  {{ (terms && terms[o.target_id]?.name) || "" }}
                </v-chip>
              </v-chip-group>
            </div>
          </div>

          <!-- Submit Edit Request -->
          <div
            style="display: flex; justify-content: flex-end; margin-top: 5em"
          >
            <v-btn
              text
              @click="
                sendMail();
                return false;
              "
              >Suggest an Edit</v-btn
            >
          </div>
        </div>

        <!-- <pre>{{ entity }}</pre> -->
        <!-- <div v-html="reUrledContent"></div> -->
      </div>
    </div>
    <div
      v-if="isNotFound"
      style="
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      "
    >
      No Content
    </div>
  </div>
</template>

<script>
import { HOST } from "../api/config";
import { apiGetEntity, initTermMap } from "../api/fetch";
import Vue from "vue";

// import debounce from "debounce";

export default {
  name: "Details",
  data: () => ({
    isNotFound: false,
    IT_EMAIL: "shakir@insidetravel.ca",
    SOCIAL_PLATFORMS: {
      addthis: "https://www.addthis.com/",
      facebook: "https://www.facebook.com/",
      flickr: "https://www.flickr.com/",
      foursquare: "https://foursquare.com/",
      googleplus: "https://plus.google.com/",
      instagram: "https://instagram.com/",
      linkedin: "https://www.linkedin.com/",
      pinterest: "https://pinterest.com/",
      rss: "",
      slideshare: "https://www.slideshare.net/",
      tumblr: "https://tumblr.com/",
      twitter: "https://twitter.com/",
      vimeo: "https://vimeo.com/",
      youtube: "https://www.youtube.com/",
    },
    details: "",
    entity: {},
    terms: null,
  }),

  // props: [
  //     'data',
  // ],

  computed: {
    // reUrledContent: () => this.data.details.replace('href="/', 'href="/details/'),
    reUrledContent() {
      const details = this.$route.params?.details;

      return details ? details.replace('href="/', 'href="/details/') : "";
    },
  },

  watch: {
    $route(to) {
      if (to.name === "Details") {
        // console.log("route to", to);
        this.loadDetails();
      }
    },
  },

  created() {
    initTermMap().then(() => {
      this.terms = Vue.prototype.$terms;
    });
  },

  mounted() {
    this.loadDetails();
  },

  beforeMount() {
    initTermMap().then(() => {
      this.terms = Vue.prototype.$terms;
    });
  },

  methods: {
    sendMail: function () {
      const url = this.entity.path[0]?.alias ?? "";
      const link =
        `mailto:${this.IT_EMAIL}` +
        "?subject=" +
        encodeURIComponent("Suggestion for Editing and Correcting: ") +
        "&body=" +
        `URL: ${encodeURIComponent(url)}%0D%0A%0D%0A`;
      window.location.href = link;
    },

    loadDetails() {
      this.host = HOST;

      // console.log(this.$route.params);
      const type = this.$route.params.type;
      const name = this.$route.params.name;
      apiGetEntity(`${type}/${name}`)
        .then((v) => {
          this.isNotFound = !v;
          v ? this.patchRefs(v) : this.notFound();
        })
        .catch((e) => {
          console.error("fetch", e);
        });

      // this.details = {...this.data.details};
      // this.$router.push({path:`/details${this.data.link}`})
      // this.details = this.data;
    },

    notFound() {},

    patchRefs(entity) {
      const typeId = this.g(entity.type)?.target_id?.toLowerCase();
      if (!typeId) {
        return;
      }
      if (
        typeId.includes("organization") &&
        entity.field_associate.length > 0
      ) {
        this._fetchAssociates(entity);
      } else if (
        typeId.includes("associate") &&
        entity.field_organization.length > 0
      ) {
        this._fetchOrg(entity);
      } else {
        this.entity = entity;
      }
    },

    _fetchAssociates(entity) {
      Promise.all(
        entity.field_associate.map((e) => {
          apiGetEntity(e.url.slice(1)).then((res) => {
            this.$set(e, "title", this.g(res.title));
            this.$set(e, "type", e.url.split("/")[1]);
            this.$set(e, "name", e.url.split("/")[2]);
            // this.$set(e, "details", this.g(res.body));
            this.$set(e, "details", "");
          });
        })
      ).then(() => {
        this.entity = entity;
      });
    },

    _fetchOrg(entity) {
      Promise.all(
        entity.field_organization.map((e) => {
          apiGetEntity(e.url.slice(1)).then((res) => {
            this.$set(e, "title", this.g(res.title));
            this.$set(e, "type", e.url.split("/")[1]);
            this.$set(e, "name", e.url.split("/")[2]);
            // this.$set(e, "details", this.g(res.body));
            this.$set(e, "details", "");
          });
        })
      ).then(() => {
        this.entity = entity;
      });
    },

    _pickValue(o) {
      if (o.processed) {
        return o.processed.replace(/<p>\s*<\/p>/g, "");
      } else if (o.summary) {
        return o.summary;
      } else if (Object.keys(o).length === 1 && o.value) {
        return "" + o.value;
      } else {
        return o;
      }
    },

    compareTerms(a, b) {
      const aa = (this.terms && this.terms[a.target_id]?.name) || "";
      const bb = (this.terms && this.terms[b.target_id]?.name) || "";
      return aa.localeCompare(bb);
    },

    processBody(entity, isSummary = false) {
      return isSummary ? entity.summary : entity.value;
    },

    g(array, fn = null) {
      if (!array || array.length < 1) {
        return "";
      }
      if (fn) {
        return fn(array[0]) || "";
      }
      return this._pickValue(array[0]) || "";
    },

    gs(array) {
      if (!array || array.length < 1) {
        return [];
      }
      return array.map((o) => this._pickValue(o));
    },

    goTermLink(o) {
      this.$router.push({
        path: `/cat/${o.url.split("/").slice(-2).join("/")}`,
        query: { tid: o.target_id },
      });
    },
    getAvatarUrl(i) {
      if (this.g(i.type).target_id?.toLowerCase().includes("organization")) {
        return this.g(i.field_logo).url || null;
      } else if (
        this.g(i.type).target_id?.toLowerCase().includes("associate")
      ) {
        return this.g(i.field_image).url || null;
      }
    },
    genSocialLink(entity) {
      const links = this.gs(entity.field_social_media_links);
      const r = links.map((i) => ({
        ...i,
        link: this.SOCIAL_PLATFORMS[i.platform] + i.value,
      }));
      // console.log(r);
      return r;
    },
  },
};
</script>

<style scoped>
.v-chip {
  /* font-size: x-small; */
  margin: 0.2em 0.1em;
}

.tags {
  border-top: solid 1px lightgray;
  padding: 1em 1em 1em 0;
  margin-top: 1em;
}

.v-chip::before {
  background-color: #fff;
}

.tags .v-chip:hover {
  background-color: #ffd600;
}

.tight {
  /* color: red; */
  /* background: green; */
  margin: 0;
  padding: 0.2em 0.2em 0.2em 1em;
}

.contact-section >>> .v-chip .v-chip__content {
  font-size: 0.875rem;
}
.tight >>> .v-slide-group__content {
  font-size: 0.875rem;
  padding-top: 0.4rem;
}
.address {
  margin-left: 0;
  padding-left: 0.1em;
}

.address >>> p {
  margin-bottom: 1em;
}

.contact-section .theme--light.v-chip {
  /* padding-left: 0; */
  margin-left: 0;
}

.contact-section .theme--light.v-chip:not(.v-chip--active) {
  background: none;
  text-decoration: underline;
}
</style>
