<template>
  <div>
    <!-- {{ params }}
    {{ query }} -->
    <!-- <pre>{{ terms }}</pre> -->
    <!-- <pre>{{ entities[0] }}</pre> -->
    <v-col
      class="flex-grow-1 flex-shrink-0"
      style="max-width: 100%; overflow-x: hidden"
    >
      <!-- result list -->
      <div style="display: block; position: relative; height: 100%">
        <template>
          <v-toolbar color="grey lighten-2" light style="height: 2.5rem">
            <v-toolbar-title
              v-text="listTitle"
              style="font-size: medium; height: 3rem"
            >
            </v-toolbar-title>
          </v-toolbar>
          <v-progress-linear
            v-if="loading"
            indeterminate
            color="blue"
            style="margin-top: -0.25em"
          ></v-progress-linear>
          <div
            style="
              display: flex;
              flex-direction: column;
              overflow-y: auto;
              height: calc(100vh - 28.5rem);
            "
          >
            <v-list two-line>
              <v-list-item-group active-class="blue--text">
                <template v-for="(item, index) in items">
                  <v-list-item
                    :key="item.index"
                    @click="
                      showDetails(item);
                      showSearchResult = false;
                    "
                  >
                    <template>
                      <v-list-item-avatar color="yellow accent-4">
                        <v-icon
                          style="color: grey"
                          v-if="!item.avatar && item.type == 'associate'"
                          >mdi-account</v-icon
                        >
                        <v-icon
                          style="color: grey"
                          v-if="!item.avatar && item.type == 'organization'"
                          >mdi-domain</v-icon
                        >
                        <v-img v-if="item.avatar" :src="item.avatar"></v-img>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          color="light-blue"
                          style="font-weight: bold"
                          v-html="item.title"
                        ></v-list-item-title>
                        <!-- <span>{{ item.tids }}</span> -->
                        <div
                          class="d-flex"
                          style="align-items: flex-start"
                          v-if="item.sectors"
                        >
                          <v-icon class="list-icon"
                            >mdi-home-city-outline</v-icon
                          >
                          <v-list-item-subtitle
                            style="padding-left: 1em"
                            class="text--primary, list-subtitle, text-wrap"
                            v-html="item.sectors"
                          >
                          </v-list-item-subtitle>
                        </div>
                        <div
                          class="d-flex"
                          style="align-items: flex-start"
                          v-if="item.specialties"
                        >
                          <v-icon class="list-icon">mdi-camera-outline</v-icon>
                          <v-list-item-subtitle
                            style="padding-left: 1em"
                            class="text--primary, list-subtitle, text-wrap"
                            v-html="item.specialties"
                          >
                          </v-list-item-subtitle>
                        </div>
                        <div
                          class="d-flex"
                          style="align-items: flex-start"
                          v-if="item.destinations"
                        >
                          <v-icon class="list-icon"
                            >mdi-map-marker-radius-outline</v-icon
                          >
                          <v-list-item-subtitle
                            style="padding-left: 1em"
                            class="text--primary, list-subtitle, text-wrap"
                            v-html="item.destinations"
                          >
                          </v-list-item-subtitle>
                        </div>
                      </v-list-item-content>
                    </template>
                  </v-list-item>

                  <v-divider
                    v-if="index < entities.length - 1"
                    :key="index"
                  ></v-divider>
                </template>
              </v-list-item-group>
            </v-list>
          </div>
        </template>
      </div>
    </v-col>
  </div>
</template>

<script>
import { HOST } from "../api/config";
import { apiGetCatById, initTermMap } from "../api/fetch";
import Vue from "vue";

// import debounce from "debounce";

export default {
  name: "Details",
  data: () => ({
    terms: Vue.prototype.$terms,
    params: {},
    query: {},
    entities: [],
    loading: false,
  }),

  // props: [
  //     'data',
  // ],

  computed: {
    listTitle() {
      const title =
        this.query?.tid && this.terms && this.terms[this.query.tid]
          ? this.terms[this.query.tid].name
          : "";
      return title;
    },

    items() {
      if (!this.terms) {
        return [];
      }

      function getTermNames(tids, map) {
        if (!tids || !map) return "";

        return tids
          .split(", ")
          .map((i) => (map[i] && map[i].name) || i)
          .sort()
          .join(", ");
      }

      return this.entities?.map((i) => ({
        index: i.uuid,
        tids: Array.from(
          new Set([
            ...(i.tid_sectors.split(",") || ""),
            ...(i.tid_specialties.split(",") || ""),
            ...(i.tid_destinations.split(",") || ""),
          ])
        ),
        type: i.type.toLowerCase().split(" - ")[1],
        title: unescape(i.title),
        specialties: getTermNames(i.tid_specialties, this.terms),
        sectors: getTermNames(i.tid_sectors, this.terms),
        destinations: getTermNames(i.tid_destinations, this.terms),
        link: i.url,
        avatar: this.getAvatar(i),
      }));
    },
  },

  watch: {
    $route(to) {
      if (to.name === "Category") {
        this.loadDetails();
      }
    },
  },

  created() {
    initTermMap().then((this.terms = Vue.prototype.$terms));
  },

  mounted() {
    this.loadDetails();
  },

  beforeMount() {},

  methods: {
    loadDetails() {
      this.loading = true;
      this.host = HOST;

      // console.log("cat router", this.$route.params);
      this.params = this.$route.params;
      this.query = this.$route.query;
      if (this.query.tid) {
        apiGetCatById(this.query.tid).then((v) => {
          this.entities = v;
          this.loading = false;
        });
      }
    },

    getAvatar(i) {
      if (i.type?.toLowerCase().includes("organization")) {
        return i.field_logo ? `https://${HOST}${unescape(i.field_logo)}` : "";
      } else if (i.type?.toLowerCase().includes("associate")) {
        return i.field_image ? `https://${HOST}${unescape(i.field_image)}` : "";
      }
    },

    showDetails(item) {
      // console.log("showDetails", item.link);
      // item.details += "";
      // let type, title;
      // [type, title] = [...item.url.split("/").filter((s) => s !== "")];
      this.$router.push({
        path: `/details${item.link}`,
        // path: `/details/${item.link}`,
        // name: "Details",
        // params: {
        //   type: type,
        //   name: title,
        //   details: item.details,
        // },
      });
    },
  },
};
</script>

<style scoped>
.v-chip {
  /* font-size: x-small; */
  margin: 0.2em 0.1em;
}

.v-chip:hover {
  background-color: #ffd600;
}
.list-icon {
  font-size: 1.2em !important;
}
.list-subtitle {
  padding-left: 1em;
}
.tight {
  /* color: red; */
  /* background: green; */
  margin: 0;
  padding: 0.2em 0.2em 0.2em 1em;
}
</style>
