<template>
  <home-banner></home-banner>
</template>

<script>
import HomeBanner from "../components/home-banner.vue";
export default {
  name: "Home",

  components: {
    HomeBanner,
  },
};
</script>
