var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticStyle: {
      "position": "relative",
      "display": "flex",
      "flex-direction": "column"
    },
    attrs: {
      "height": "100%"
    }
  }, [_c('v-card', {
    staticClass: "home-banner",
    attrs: {
      "color": "yellow accent-4"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "row",
      "width": "100%",
      "justify-content": "space-between"
    }
  }, [_c('v-card-title', {
    staticStyle: {
      "flex-direction": "column",
      "margin-top": "-0.8em"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "row",
      "align-items": "center",
      "cursor": "pointer"
    },
    on: {
      "click": function click($event) {
        _vm.clearSearchResult();

        _vm.$router.push({
          name: 'Home'
        }).catch(function () {});
      }
    }
  }, [_c('img', {
    staticClass: "yellow-outline",
    staticStyle: {
      "height": "2em",
      "padding": "0.2em"
    },
    attrs: {
      "src": require("../assets/images/LogoP.svg")
    }
  }), _c('img', {
    staticClass: "yellow-outline",
    staticStyle: {
      "height": "1.2rem",
      "padding-left": "0.3em",
      "margin-top": "0.5em"
    },
    attrs: {
      "src": require("../assets/images/LogoTextBlack.svg")
    }
  })]), _c('div', {
    staticStyle: {
      "padding-left": "1em",
      "opacity": "0.8"
    }
  })]), [_c('div', {
    staticClass: "text-center",
    staticStyle: {
      "margin-top": "0.4em"
    }
  }, [_c('v-dialog', {
    attrs: {
      "persistent": "",
      "width": "500"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_vm.isLoggedIn ? _c('v-btn', {
          attrs: {
            "text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.logout();
            }
          }
        }, [_vm._v("Logout")]) : _vm._e(), !_vm.isLoggedIn ? _c('v-btn', _vm._g(_vm._b({
          attrs: {
            "text": ""
          }
        }, 'v-btn', attrs, false), on), [_vm._v(" Join • Login ")]) : _vm._e()];
      }
    }]),
    model: {
      value: _vm.showLoginDialog,
      callback: function callback($$v) {
        _vm.showLoginDialog = $$v;
      },
      expression: "showLoginDialog"
    }
  }, [_c('v-card', [_c('div', {
    staticStyle: {
      "padding": "1em 3em"
    }
  }, [_c('v-tabs', {
    model: {
      value: _vm.currentTab,
      callback: function callback($$v) {
        _vm.currentTab = $$v;
      },
      expression: "currentTab"
    }
  }, [_c('v-tab', {
    attrs: {
      "href": "#tab-login"
    }
  }, [_vm._v("Login ")]), _c('v-tab', {
    attrs: {
      "href": "#tab-join"
    }
  }, [_vm._v("Join")])], 1), _c('v-tabs-items', {
    model: {
      value: _vm.currentTab,
      callback: function callback($$v) {
        _vm.currentTab = $$v;
      },
      expression: "currentTab"
    }
  }, [_c('v-tab-item', {
    attrs: {
      "value": "tab-login"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "basil",
      "flat": ""
    }
  }, [_c('v-card-text', [_vm._v("Already a member? Login to access premium features")]), _c('v-form', {
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-container', [_c('v-col', [_c('v-row', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": _vm.emailRules,
      "label": "E-mail",
      "required": ""
    },
    model: {
      value: _vm.loginEmail,
      callback: function callback($$v) {
        _vm.loginEmail = $$v;
      },
      expression: "loginEmail"
    }
  })], 1), _c('v-row', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-text-field', {
    staticClass: "input-group--focused",
    attrs: {
      "append-icon": _vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',
      "rules": [_vm.passwordRules.required, _vm.passwordRules.min],
      "type": _vm.showPassword ? 'text' : 'password',
      "name": "input-10-2",
      "label": "Password",
      "hint": "At least 8 characters",
      "value": ""
    },
    on: {
      "click:append": function clickAppend($event) {
        _vm.showPassword = !_vm.showPassword;
      }
    },
    model: {
      value: _vm.loginPassword,
      callback: function callback($$v) {
        _vm.loginPassword = $$v;
      },
      expression: "loginPassword"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1), _c('v-tab-item', {
    attrs: {
      "value": "tab-join"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "basil",
      "flat": ""
    }
  }, [_c('v-card-text', [_vm._v("Not a member yet? Sign up to access premium features")]), _c('v-form', {
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-container', [_c('v-col', [_c('v-row', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": _vm.emailRules,
      "label": "E-mail",
      "required": ""
    },
    model: {
      value: _vm.loginEmail,
      callback: function callback($$v) {
        _vm.loginEmail = $$v;
      },
      expression: "loginEmail"
    }
  })], 1), _c('v-row', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-text-field', {
    staticClass: "input-group--focused",
    attrs: {
      "append-icon": _vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',
      "rules": [_vm.passwordRules.required, _vm.passwordRules.min],
      "type": _vm.showPassword ? 'text' : 'password',
      "name": "input-10-2",
      "label": "Password",
      "hint": "At least 8 characters",
      "value": ""
    },
    on: {
      "click:append": function clickAppend($event) {
        _vm.showPassword = !_vm.showPassword;
      }
    },
    model: {
      value: _vm.loginPassword,
      callback: function callback($$v) {
        _vm.loginPassword = $$v;
      },
      expression: "loginPassword"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.showLoginDialog = false;
      }
    }
  }, [_vm._v(" Cancel ")]), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    domProps: {
      "textContent": _vm._s(_vm.currentTab == 'tab-login' ? 'Login' : 'Join')
    },
    on: {
      "click": function click($event) {
        return _vm.login(_vm.loginEmail, _vm.loginPassword, _vm.currentTab);
      }
    }
  })], 1)], 1)], 1)], 1)]], 2), _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "row"
    }
  }, [_c('h3', {
    staticStyle: {
      "align-self": "end",
      "margin-bottom": "-10px"
    }
  }, [_vm._v(" Canada’s Travel Industry Directory ")]), _c('div', {
    staticClass: "bg-arrow"
  })]), _c('v-card-text', {
    staticClass: "searchbox"
  }, [_c('v-autocomplete', {
    ref: "autocomplete",
    attrs: {
      "items": _vm.mini ? _vm.items : [],
      "loading": _vm.isLoading,
      "search-input": _vm.search,
      "no-filter": "",
      "clearable": "",
      "background-color": "white",
      "outlined": "",
      "hide-no-data": "",
      "hide-details": "",
      "item-text": "title",
      "item-value": "search_api_excerpt",
      "label": "",
      "placeholder": _vm.searchPlaceHolder,
      "return-object": "",
      "append-icon": ""
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        _vm.search = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.search = $event;
      },
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.onEnterKey.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('v-list-item-content', [_c('v-list-item-title', {
          domProps: {
            "innerHTML": _vm._s(item.title)
          }
        }), _c('v-list-item-subtitle', {
          domProps: {
            "innerHTML": _vm._s(item.excerpt)
          }
        })], 1)];
      }
    }]),
    model: {
      value: _vm.select,
      callback: function callback($$v) {
        _vm.select = $$v;
      },
      expression: "select"
    }
  })], 1), _vm.search && this.searchPhraseShortest < this.MIN_SEARCH_STR_LENGTH ? _c('div', {
    staticStyle: {
      "opacity": "0.5",
      "margin-top": "-3em",
      "padding-bottom": "1.5em",
      "font-size": "small"
    }
  }, [_vm._v(" " + _vm._s("Words shorter than ".concat(_vm.MIN_SEARCH_STR_LENGTH, " characters will be ignored")) + " ")]) : _vm._e()], 1), _c('div', {
    staticStyle: {
      "display": "block",
      "height": "100%",
      "overflow-y": "auto",
      "position": "relative"
    }
  }, [_c('v-card', {
    staticStyle: {
      "display": "flex",
      "flex-grow": "1",
      "flex-direction": "row",
      "height": "100%"
    }
  }, [_c('v-navigation-drawer', {
    staticClass: "flex-shrink-0",
    attrs: {
      "mini-variant": _vm.mini,
      "permanent": ""
    },
    on: {
      "update:miniVariant": function updateMiniVariant($event) {
        _vm.mini = $event;
      },
      "update:mini-variant": function updateMiniVariant($event) {
        _vm.mini = $event;
      }
    },
    model: {
      value: _vm.drawer,
      callback: function callback($$v) {
        _vm.drawer = $$v;
      },
      expression: "drawer"
    }
  }, [_c('div', {
    staticClass: "filter-header",
    attrs: {
      "color": "gray accent-4",
      "light": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        _vm.mini = !_vm.mini;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-tune")])], 1), !_vm.mini && _vm.showSearchResult ? _c('h5', {
    staticStyle: {
      "align-self": "center"
    }
  }, [_vm._v(" Filters ")]) : _vm._e(), !_vm.mini && !_vm.showSearchResult ? _c('h5', {
    staticStyle: {
      "align-self": "center"
    }
  }, [_vm._v(" Browse ")]) : _vm._e(), !_vm.mini ? _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        _vm.mini = !_vm.mini;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-chevron-left")])], 1) : _vm._e()], 1), _c('v-divider'), _vm.mini ? _c('v-list', {
    attrs: {
      "dense": ""
    }
  }, _vm._l(_vm.menuItems, function (item) {
    return _c('v-list-item', {
      key: item.title,
      attrs: {
        "link": ""
      }
    }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v(_vm._s(item.icon))])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(item.title))])], 1)], 1);
  }), 1) : _vm._e(), _c('div', {
    staticStyle: {
      "font-size": "small",
      "height": "calc(100vh - 25.5rem)",
      "width": "100%",
      "display": "block",
      "position": "absolute",
      "overflow-y": "auto",
      "overflow-x": "auto"
    }
  }, [!_vm.mini ? _c('v-treeview', {
    ref: "tree",
    attrs: {
      "dense": "",
      "selectable": _vm.showSearchResult,
      "value": _vm.selectedNodes,
      "selected-color": "primary",
      "items": _vm.filteredCategories
    },
    on: {
      "update:value": function updateValue($event) {
        _vm.selectedNodes = $event;
      },
      "input": function input($event) {
        _vm.searchTerm && _vm.onFilter($event);
      }
    },
    scopedSlots: _vm._u([!_vm.showSearchResult ? {
      key: "label",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('v-tooltip', {
          attrs: {
            "top": "",
            "color": "primary",
            "dark": "",
            "open-delay": "500",
            "hidden": "true"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref4) {
              var on = _ref4.on,
                  attrs = _ref4.attrs;
              return [!_vm.showSearchResult ? _c(_vm.makeRouterLink(item) ? 'router-link' : 'span', {
                tag: "router-link",
                style: _vm.routerLinkStyle(item),
                attrs: {
                  "to": _vm.makeRouterLink(item)
                }
              }, [item.icon ? _c('v-icon', [_vm._v(" " + _vm._s(item.icon) + " ")]) : _vm._e(), _c('v-chip', _vm._g(_vm._b({
                style: _vm.makeRouterLink(item) ? 'cursor: pointer' : '',
                attrs: {
                  "small": "",
                  "label": "",
                  "color": "white"
                },
                on: {
                  "click": function click($event) {
                    return _vm.clearSearchResult();
                  }
                }
              }, 'v-chip', attrs, false), on), [_vm._v(" " + _vm._s(item.name) + " ")])], 1) : _vm._e()];
            }
          }], null, true)
        }, [_c('span', [_vm._v(_vm._s(item.name))])])];
      }
    } : null, _vm.showSearchResult ? {
      key: "prepend",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [item.icon ? _c('v-icon', [_vm._v(" " + _vm._s(item.icon) + " ")]) : _vm._e()];
      }
    } : null], null, true)
  }) : _vm._e()], 1)], 1), _c('v-col', {
    staticClass: "flex-grow-1 flex-shrink-0",
    staticStyle: {
      "max-width": "100%",
      "overflow-x": "hidden"
    }
  }, [_c('router-view', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.showSearchResult,
      expression: "!showSearchResult"
    }]
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showSearchResult,
      expression: "showSearchResult"
    }],
    staticStyle: {
      "display": "block",
      "position": "relative",
      "height": "100%",
      "margin-top": "-0.5rem"
    }
  }, [[_c('v-toolbar', {
    staticStyle: {
      "height": "2.5rem"
    },
    attrs: {
      "color": "grey lighten-2",
      "light": ""
    }
  }, [_c('v-toolbar-title', {
    staticStyle: {
      "font-size": "medium",
      "height": "3rem"
    },
    domProps: {
      "textContent": _vm._s(_vm.searchTitle)
    }
  })], 1), _c('div', {
    staticStyle: {
      "height": "calc(100vh - 27rem)",
      "display": "flex",
      "flex-direction": "column",
      "overflow-y": "auto"
    }
  }, [_c('v-list', {
    attrs: {
      "two-line": ""
    }
  }, [_c('v-list-item-group', {
    attrs: {
      "active-class": "blue--text"
    },
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }, [_vm._l(_vm.items, function (item, index) {
    return _c('div', {
      key: item.index
    }, [_c('v-list-item', {
      on: {
        "click": function click($event) {
          _vm.showDetails(item);

          _vm.showSearchResult = false;
        }
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref6) {
          var active = _ref6.active;
          return [_c('v-list-item-avatar', {
            attrs: {
              "color": "yellow accent-4"
            }
          }, [!item.avatar && item.type == 'associate' ? _c('v-icon', {
            staticStyle: {
              "color": "grey"
            }
          }, [_vm._v("mdi-account")]) : _vm._e(), !item.avatar && item.type == 'organization' ? _c('v-icon', {
            staticStyle: {
              "color": "grey"
            }
          }, [_vm._v("mdi-domain")]) : _vm._e(), item.avatar ? _c('v-img', {
            attrs: {
              "src": item.avatar
            }
          }) : _vm._e()], 1), _c('v-list-item-content', [_c('v-list-item-title', {
            staticStyle: {
              "font-weight": "bold"
            },
            attrs: {
              "color": "light-blue"
            }
          }, [_c('text-highlight', {
            attrs: {
              "queries": _vm.searchTerm.split(' ')
            }
          }, [_vm._v(" " + _vm._s(item.title) + " ")])], 1), _c('div', {
            staticClass: "d-flex",
            staticStyle: {
              "align-items": "flex-start"
            }
          }, [_c('v-list-item-subtitle', {
            staticClass: "text--primary, list-subtitle, text-wrap",
            domProps: {
              "innerHTML": _vm._s(item.organization)
            },
            on: {
              "click": function click($event) {
                $event.stopPropagation();
              }
            }
          })], 1), item.sectors ? _c('div', {
            staticClass: "d-flex",
            staticStyle: {
              "align-items": "flex-start"
            }
          }, [_c('v-icon', {
            staticClass: "list-icon"
          }, [_vm._v("mdi-home-city-outline")]), _c('v-list-item-subtitle', {
            staticClass: "text--primary, list-subtitle, text-wrap",
            staticStyle: {
              "padding-left": "1em"
            }
          }, [_c('text-highlight', {
            attrs: {
              "queries": _vm.searchTerm.split(' ')
            }
          }, [_vm._v(" " + _vm._s(item.sectors) + " ")])], 1)], 1) : _vm._e(), item.specialties ? _c('div', {
            staticClass: "d-flex",
            staticStyle: {
              "align-items": "flex-start"
            }
          }, [_c('v-icon', {
            staticClass: "list-icon"
          }, [_vm._v("mdi-camera-outline")]), _c('v-list-item-subtitle', {
            staticClass: "text--primary, list-subtitle, text-wrap",
            staticStyle: {
              "padding-left": "1em"
            }
          }, [_c('text-highlight', {
            attrs: {
              "queries": _vm.searchTerm.split(' ')
            }
          }, [_vm._v(" " + _vm._s(item.specialties) + " ")])], 1)], 1) : _vm._e(), item.destinations ? _c('div', {
            staticClass: "d-flex",
            staticStyle: {
              "align-items": "flex-start"
            }
          }, [_c('v-icon', {
            staticClass: "list-icon"
          }, [_vm._v("mdi-map-marker-radius-outline")]), _c('v-list-item-subtitle', {
            staticClass: "text--primary, list-subtitle, text-wrap",
            staticStyle: {
              "padding-left": "1em"
            }
          }, [_c('text-highlight', {
            attrs: {
              "queries": _vm.searchTerm.split(' ')
            }
          }, [_vm._v(" " + _vm._s(item.destinations) + " ")])], 1)], 1) : _vm._e()], 1), _c('v-list-item-action', [_c('v-list-item-action-text', {
            attrs: {
              "color": "grey lighten-3"
            },
            domProps: {
              "textContent": _vm._s(item.score)
            }
          }), !active ? _c('v-icon', {
            attrs: {
              "color": "grey lighten-1"
            }
          }) : _c('v-icon', {
            attrs: {
              "color": "yellow darken-3"
            }
          })], 1)];
        }
      }], null, true)
    }), index < _vm.items.length - 1 ? _c('v-divider', {
      key: index
    }) : _vm._e()], 1);
  })], 2)], 1)], 1)]], 2)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }