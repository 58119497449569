<template>
  <div class="footer text-center">
    <div class="footer-link-section">
      <router-link
        class="footer-link"
        :class="{ disabled: !s.link }"
        v-for="s in links"
        :key="s.label"
        :to="s.link ?? ''"
        >{{ s.label }}</router-link
      >
    </div>
    <v-divider></v-divider>
    <v-card-text
      class="black--text"
      style="display: flex; align-items: baseline"
    >
      Copyright © {{ new Date().getFullYear() }}&nbsp;
      <a
        style="text-decoration: none"
        target="_blank"
        href="https://www.interpub.ca"
        >International Publishing Group Inc.</a
      >&nbsp; Find suppliers, destinations, and specialties.
      <div class="social-media">
        <v-btn v-for="icon in icons" :key="icon" icon>
          <v-icon>
            {{ icon }}
          </v-icon>
        </v-btn>
      </div>
    </v-card-text>
  </div>
</template>

<script>
import { HOST } from "../api/config";
// import debounce from "debounce";

export default {
  name: "Footer",
  data: () => ({
    icons: [
      "mdi-facebook",
      "mdi-instagram",
      "mdi-linkedin",
      "mdi-twitter",
      "mdi-youtube",
    ],
    links: [
      { label: "About", link: "/about" },
      { label: "Contact", link: "/contact" },
      { label: "FAQ", link: "/faq" },
      { label: "Subscribe", link: "" },
      { label: "Privacy Policy", link: "/privacy" },
      { label: "Cookie Policy", link: "/cookies" },
      { label: "Terms and Conditions", link: "/terms" },
    ],
  }),

  computed: {},

  mounted() {
    this.host = HOST;
  },

  beforeMount() {},
};
</script>

<style scoped>
.footer {
  background-color: #fdf7d9;
  width: 100%;
  font-size: 0.8rem;
}
.footer >>> .v-card__text {
  padding: 0.5em 1em 1em 1em;
  font-size: 0.8rem;
}
.footer-link-section {
  padding: 1em 1em 0.5em 1em;
}
.footer-link {
  margin: 0.5em 1em;
  text-decoration: none;
  color: black;
}
.social-media {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-grow: 1;
  align-content: center;
  justify-content: flex-end;
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
</style>
