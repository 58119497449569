var render = function () {
  var _vm$g, _vm$g$target_id, _vm$gs, _vm$gs$map, _vm$gs$map$filter;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "overflow-y": "auto",
      "height": "calc(100vh - 24.5rem)"
    }
  }, [!_vm.isNotFound ? _c('div', [!_vm.entity ? _c('div', {
    staticStyle: {
      "text-align": "center",
      "margin-top": "20%"
    }
  }, [_vm._v(" Nothing to show from this URL ")]) : _vm._e(), _vm.entity ? _c('div', [_c('div', {
    staticClass: "mx-auto",
    staticStyle: {
      "padding": "3em",
      "margin": "2em"
    },
    attrs: {
      "max-width": "800"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "row"
    }
  }, [_c('v-avatar', {
    staticStyle: {
      "margin-right": "0.5em"
    },
    attrs: {
      "size": "56"
    }
  }, [!_vm.getAvatarUrl(_vm.entity) ? _c('v-icon', {
    staticStyle: {
      "color": "grey",
      "background": "#ffd600"
    }
  }, [_vm._v(_vm._s((_vm$g = _vm.g(_vm.entity.type)) !== null && _vm$g !== void 0 && (_vm$g$target_id = _vm$g.target_id) !== null && _vm$g$target_id !== void 0 && _vm$g$target_id.includes("organization") ? "mdi-domain" : "mdi-account"))]) : _vm._e(), _vm.getAvatarUrl(_vm.entity) ? _c('img', {
    attrs: {
      "alt": "avatar",
      "src": _vm.getAvatarUrl(_vm.entity),
      "aspect-ratio": "1"
    }
  }) : _vm._e()], 1), _c('div', [_c('h1', [_vm._v(" " + _vm._s(_vm.g(_vm.entity.title)) + " ")]), _vm.g(_vm.entity.field_job_title_territory) ? _c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.g(_vm.entity.field_job_title_territory))
    }
  }) : _vm._e(), _vm.g(_vm.entity.field_addresses_html) ? _c('v-card-subtitle', {
    staticClass: "address",
    domProps: {
      "innerHTML": _vm._s(_vm.g(_vm.entity.field_addresses_html))
    }
  }) : _vm._e()], 1)], 1), _vm.g(_vm.entity.body) ? _c('v-divider', {
    staticStyle: {
      "margin-top": "10px"
    }
  }) : _vm._e(), _vm.g(_vm.entity.body) ? _c('v-card-text', {
    staticClass: "text--primary",
    domProps: {
      "innerHTML": _vm._s(_vm.g(_vm.entity.body, _vm.processBody))
    }
  }) : _vm._e(), _c('v-divider', {
    staticStyle: {
      "margin-bottom": "10px"
    }
  }), _c('div', {
    staticClass: "contact-section"
  }, [_vm.gs(_vm.entity.field_skype).length > 0 ? _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "row"
    }
  }, [_c('v-chip-group', {
    staticClass: "tight",
    staticStyle: {
      "display": "inline-block",
      "margin": "0 0.1em 0 -1em"
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "small": ""
    }
  }, [_vm._v(" mdi-tablet-cellphone ")]), _vm._v(" Skype: ")], 1), _c('v-chip-group', {
    attrs: {
      "column": ""
    }
  }, _vm._l(_vm.gs(_vm.entity.field_skype), function (s) {
    return _c('v-chip', {
      key: s.first + s.second,
      attrs: {
        "link": "",
        "small": "",
        "href": "skype:".concat(s.second, "?call")
      }
    }, [_vm._v(" " + _vm._s(s.first) + " ")]);
  }), 1)], 1) : _vm._e(), _vm.gs(_vm.entity.field_tels).length > 0 ? _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "row"
    }
  }, [_c('v-chip-group', {
    staticClass: "tight",
    staticStyle: {
      "display": "inline-block",
      "margin": "0 0.1em 0 -1em"
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "small": ""
    }
  }, [_vm._v(" mdi-phone-outline ")]), _vm._v(" Tel: ")], 1), _c('v-chip-group', {
    attrs: {
      "column": ""
    }
  }, _vm._l(_vm.gs(_vm.entity.field_tels), function (e) {
    var _e$first;

    return _c('v-chip', {
      key: e.first + e.second,
      attrs: {
        "link": "",
        "small": "",
        "href": "tel:".concat(e.second)
      }
    }, [_vm._v(" " + _vm._s(e.first ? (_e$first = e.first) === null || _e$first === void 0 ? void 0 : _e$first.replace(/:$/, "") : e.second) + " ")]);
  }), 1)], 1) : _vm._e(), _vm.gs(_vm.entity.field_emails).length > 0 ? _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "row"
    }
  }, [_c('v-chip-group', {
    staticClass: "tight",
    staticStyle: {
      "display": "inline-block",
      "margin": "0 0.1em 0 -1em"
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "small": ""
    }
  }, [_vm._v(" mdi-email-outline ")]), _vm._v(" Email: ")], 1), _c('v-chip-group', {
    attrs: {
      "column": ""
    }
  }, _vm._l(_vm.gs(_vm.entity.field_emails), function (e) {
    var _e$first2;

    return _c('v-chip', {
      key: e.first + e.second,
      attrs: {
        "link": "",
        "small": "",
        "href": "mailto:".concat(e.second)
      }
    }, [_vm._v(" " + _vm._s(e.first ? (_e$first2 = e.first) === null || _e$first2 === void 0 ? void 0 : _e$first2.replace(/:$/, "") : e.second) + " ")]);
  }), 1)], 1) : _vm._e(), _vm.gs(_vm.entity.field_websites).length > 0 ? _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "row"
    }
  }, [_c('v-chip-group', {
    staticClass: "tight",
    staticStyle: {
      "display": "inline-block",
      "margin": "0 0.1em 0 -1em"
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "small": ""
    }
  }, [_vm._v(" mdi-web ")]), _vm._v(" Website: ")], 1), _c('v-chip-group', {
    attrs: {
      "column": ""
    }
  }, _vm._l(_vm.gs(_vm.entity.field_websites), function (e) {
    return _c('v-chip', {
      key: e.title + e.uri,
      attrs: {
        "link": "",
        "small": "",
        "href": "".concat(e.uri),
        "target": "_blank"
      }
    }, [_vm._v(" " + _vm._s(e.title || e.uri) + " ")]);
  }), 1)], 1) : _vm._e(), _vm.g(_vm.entity.field_headquarter) ? _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "row"
    }
  }, [_c('v-chip-group', {
    staticClass: "tight",
    staticStyle: {
      "display": "inline-block",
      "margin": "0 0.1em 0 -1em"
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "small": ""
    }
  }, [_vm._v(" mdi-domain ")]), _vm._v(" Headquarter: ")], 1), _c('v-chip-group', {
    attrs: {
      "column": ""
    }
  }, [_c('v-chip', {
    attrs: {
      "small": ""
    }
  }, [_c('router-link', {
    attrs: {
      "tag": "span",
      "to": "/details".concat(_vm.g(_vm.entity.field_headquarter).url)
    }
  }, [_vm._v(" Headquarter ")])], 1)], 1)], 1) : _vm._e(), _vm.gs(_vm.entity.field_organization).length > 0 ? _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "row"
    }
  }, [_c('v-chip-group', {
    staticClass: "tight",
    staticStyle: {
      "display": "inline-block",
      "margin": "0 0.1em 0 -1em"
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "small": ""
    }
  }, [_vm._v(" mdi-domain ")]), _vm._v(" Organization: ")], 1), _c('v-chip-group', {
    attrs: {
      "column": ""
    }
  }, _vm._l(_vm.gs(_vm.entity.field_organization), function (e) {
    return _c('v-chip', {
      key: e.url,
      attrs: {
        "small": ""
      }
    }, [e !== null && e !== void 0 && e.type ? _c('router-link', {
      attrs: {
        "tag": "span",
        "to": {
          name: 'Details',
          params: {
            type: e.type,
            name: e.name,
            details: ''
          }
        }
      }
    }, [_vm._v(" " + _vm._s(e.title) + " ")]) : _vm._e()], 1);
  }), 1)], 1) : _vm._e(), _vm.gs(_vm.entity.field_associate).length > 0 ? _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "row"
    }
  }, [_c('v-chip-group', {
    staticClass: "tight",
    staticStyle: {
      "display": "inline-block",
      "margin": "0 0.1em 0 -1em"
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "small": ""
    }
  }, [_vm._v(" mdi-account-multiple-outline ")]), _vm._v(" Team: ")], 1), _c('v-chip-group', {
    attrs: {
      "column": ""
    }
  }, _vm._l(_vm.gs(_vm.entity.field_associate), function (e) {
    return _c('v-chip', {
      key: e.url,
      attrs: {
        "small": ""
      }
    }, [e !== null && e !== void 0 && e.type ? _c('router-link', {
      attrs: {
        "tag": "span",
        "to": {
          name: 'Details',
          params: {
            type: e.type,
            name: e.name,
            details: ''
          }
        }
      }
    }, [_vm._v(" " + _vm._s(e.title) + " ")]) : _vm._e()], 1);
  }), 1)], 1) : _vm._e(), _vm.gs(_vm.entity.field_social_media_links).length > 0 ? _c('div', [_c('div', _vm._l(_vm.genSocialLink(_vm.entity), function (l) {
    return _c('v-btn', {
      key: l.platform + l.link,
      staticStyle: {
        "margin": "1em"
      },
      attrs: {
        "dark": "",
        "fab": "",
        "bottom": "",
        "color": "blue",
        "small": "",
        "href": l.link,
        "target": "_blank"
      }
    }, [_c('v-icon', [_vm._v("mdi-" + _vm._s(l.platform))])], 1);
  }), 1)]) : _vm._e()]), _c('div', {
    staticClass: "tags"
  }, [((_vm$gs = _vm.gs(_vm.entity.field_sector)) === null || _vm$gs === void 0 ? void 0 : (_vm$gs$map = _vm$gs.map(function (o) {
    var _vm$terms$o$target_id;

    return (_vm$terms$o$target_id = _vm.terms[o.target_id]) === null || _vm$terms$o$target_id === void 0 ? void 0 : _vm$terms$o$target_id.name;
  })) === null || _vm$gs$map === void 0 ? void 0 : (_vm$gs$map$filter = _vm$gs$map.filter(function (i) {
    return !!i;
  })) === null || _vm$gs$map$filter === void 0 ? void 0 : _vm$gs$map$filter.length) > 0 ? _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "row"
    }
  }, [_c('v-chip-group', {
    staticClass: "tight",
    staticStyle: {
      "display": "inline-block",
      "margin": "0 0.1em 0 -1em"
    }
  }, [_vm._v(" Sector: ")]), _c('v-chip-group', {
    attrs: {
      "column": ""
    }
  }, _vm._l(_vm.gs(_vm.entity.field_sector).sort(_vm.compareTerms), function (o) {
    var _vm$terms$o$target_id2;

    return _c('v-chip', {
      key: o.tid,
      attrs: {
        "small": ""
      },
      on: {
        "click": function click($event) {
          return _vm.goTermLink(o);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.terms && ((_vm$terms$o$target_id2 = _vm.terms[o.target_id]) === null || _vm$terms$o$target_id2 === void 0 ? void 0 : _vm$terms$o$target_id2.name) || "") + " ")]);
  }), 1)], 1) : _vm._e(), _vm.gs(_vm.entity.field_destinations).length > 0 ? _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "row"
    }
  }, [_c('v-chip-group', {
    staticClass: "tight",
    staticStyle: {
      "display": "inline-block",
      "margin": "0 0.1em 0 -1em"
    }
  }, [_vm._v(" Destinations: ")]), _c('v-chip-group', {
    attrs: {
      "column": ""
    }
  }, _vm._l(_vm.gs(_vm.entity.field_destinations).sort(_vm.compareTerms), function (o) {
    var _vm$terms$o$target_id3;

    return _c('v-chip', {
      key: o.tid,
      attrs: {
        "small": ""
      },
      on: {
        "click": function click($event) {
          return _vm.goTermLink(o);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.terms && ((_vm$terms$o$target_id3 = _vm.terms[o.target_id]) === null || _vm$terms$o$target_id3 === void 0 ? void 0 : _vm$terms$o$target_id3.name) || "") + " ")]);
  }), 1)], 1) : _vm._e(), _vm.gs(_vm.entity.field_specialties).length > 0 ? _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "row"
    }
  }, [_c('v-chip-group', {
    staticClass: "tight",
    staticStyle: {
      "display": "inline-block",
      "margin": "0 0.1em 0 -1em"
    }
  }, [_vm._v(" Specialties: ")]), _c('v-chip-group', {
    attrs: {
      "column": ""
    }
  }, _vm._l(_vm.gs(_vm.entity.field_specialties).sort(_vm.compareTerms), function (o) {
    var _vm$terms$o$target_id4;

    return _c('v-chip', {
      key: o.url,
      attrs: {
        "small": ""
      },
      on: {
        "click": function click($event) {
          return _vm.goTermLink(o);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.terms && ((_vm$terms$o$target_id4 = _vm.terms[o.target_id]) === null || _vm$terms$o$target_id4 === void 0 ? void 0 : _vm$terms$o$target_id4.name) || "") + " ")]);
  }), 1)], 1) : _vm._e()]), _c('div', {
    staticStyle: {
      "display": "flex",
      "justify-content": "flex-end",
      "margin-top": "5em"
    }
  }, [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.sendMail();

        return false;
      }
    }
  }, [_vm._v("Suggest an Edit")])], 1)], 1)]) : _vm._e()]) : _vm._e(), _vm.isNotFound ? _c('div', {
    staticStyle: {
      "width": "100%",
      "height": "100%",
      "display": "flex",
      "align-items": "center",
      "justify-content": "center"
    }
  }, [_vm._v(" No Content ")]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }