<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
    <v-footer color="gold" padless>
      <Footer></Footer>
    </v-footer>
  </v-app>
</template>

<script>
import Footer from "./components/footer.vue";
export default {
  components: { Footer },
  name: "App",

  data: () => ({
    //
  }),
};
</script>
